.main-div {
    background-color: #2D3A4B;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(238, 238, 238);
}

.login {
    position: relative;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
}

.login h3 {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 2rem;
}

.login .input-div {
    display: flex;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 5px;
    border: 1px solid hsla(0, 0%, 100%, .1);
    width: 400px;
    margin: 0 auto;
    margin-bottom: 22px;
}

.login .btn {
    width: 400px;
    margin: 0 auto;
    display: block;
}

.login span {
    padding-left: 0.8rem;
    padding-top: 0.7rem;
    padding-right: 0.5rem;
}

.login .input-form {
    background-color: transparent;
    border: transparent;
    color: white;
    padding: 0.8rem;
    width: 400px;
    font-size: 14px;
}

.login input:focus {
    outline: none;
}

.login ::placeholder {
    color: white;
    opacity: 0.7;
}