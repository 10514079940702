.orders {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 15px;
    gap: 15px;
}

.order-card {
    cursor: pointer;
    background-color: #f7f7f7;
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
}

.customer-name {
    font-size: 20px;
    font-weight: 700;
}

.customer-info,
.customer-phone,
.main-info {
    font-size: 15px;
}

.customer-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.customer-address {
    display: flex;
    align-items: center;
    gap: 15px;
}

.main-info {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sub-info {
    font-weight: 700;
}

.order-status {
    max-width: 100px;
    position: absolute;
    bottom: 5px;
    right: 15px;
}


.single-order {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 15px;
}

.single-order .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 5px;
}

.single-order .main .customer-info {
    background-color: #f7f7f7;
    border-radius: 5px;
    width: 25%;
}

.single-order .main .order-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 74%;
}

.single-order .main .customer-info .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.single-order .main .customer-info .info {
    padding: 0 10px;
}



hr {
    border-top: 1px solid black !important;
}

.delivery-dates {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.delivery-dates div {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

/* span {
    font-weight: 700;
} */

.order-info .order {
    background-color: #f7f7f7;
    border-radius: 5px;
    height: 33%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.warehouse {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

table {
    background-color: white !important;
    padding: 10px !important;
}

.purchase {
    background-color: #f7f7f7;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.history {
    background-color: #f7f7f7;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.filters {
    background-color: #f7f7f7;
    padding: 15px;
    margin: 15px 0;
}

.design-card {
    background-color: #f7f7f7;
    margin: 15px 0;
    padding: 15px 0;
}

.design-info {
    display: flex;
    padding: 0.25rem;
}

.design-key {
    width: 50%;
    text-align: end;
    margin-right: 1rem;
}

.design-value {
    text-align: start;
    margin-left: 1rem;
}


.foo {
    position: relative;
}

.bar {
    position: absolute;
    top: 45%;
    right: 3%;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.disabled {
    opacity: .65;
    cursor: not-allowed !important;
}

.alert-div {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 250px;
}

@keyframes square-animation {
    0% {
        left: 0;
        top: 0;
    }

    10.5% {
        left: 0;
        top: 0;
    }

    12.5% {
        left: 32px;
        top: 0;
    }

    23% {
        left: 32px;
        top: 0;
    }

    25% {
        left: 64px;
        top: 0;
    }

    35.5% {
        left: 64px;
        top: 0;
    }

    37.5% {
        left: 64px;
        top: 32px;
    }

    48% {
        left: 64px;
        top: 32px;
    }

    50% {
        left: 32px;
        top: 32px;
    }

    60.5% {
        left: 32px;
        top: 32px;
    }

    62.5% {
        left: 32px;
        top: 64px;
    }

    73% {
        left: 32px;
        top: 64px;
    }

    75% {
        left: 0;
        top: 64px;
    }

    85.5% {
        left: 0;
        top: 64px;
    }

    87.5% {
        left: 0;
        top: 32px;
    }

    98% {
        left: 0;
        top: 32px;
    }

    100% {
        left: 0;
        top: 0;
    }
}

.loader {
    position: relative;
    width: 96px;
    height: 96px;
    transform: rotate(45deg);
}

.loader-square {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 0px;
    background: #29488D;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    animation: square-animation 10s ease-in-out infinite both;
}

.loader-square:nth-of-type(0) {
    animation-delay: 0s;
}

.loader-square:nth-of-type(1) {
    animation-delay: -1.4285714286s;
}

.loader-square:nth-of-type(2) {
    animation-delay: -2.8571428571s;
}



.change-form {
    margin-top: 100px;
    border: 1px solid #29488D;
    border-radius: 5px;
    padding: 15px;
}

.photos-list {
    display: flex;
    padding-right: 5px;
}

.photos {
    position: relative;
}

.photos button {
    position: absolute;
    top: 0;
    left: 0;
}

.file-upload {
    position: relative;
    display: flex;
    background-color: transparent;
}


.file-upload label {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100px;
    height: 100px; */
    /* border: 1px solid #197BFF; */
    color: #1D6F42;
    /* border-radius: 50%; */
    cursor: pointer;
}


.file-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
}

.select-container {
    width: 100px;
    position: relative;
}
  
.select-container::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Modal Container */
.custom-modal {
    max-width: 70% !important;
}