/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Helvetica', sans-serif;
}

.main-content {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* overflow-x: hidden; */
}

.my-sidebar {
    position: fixed;
    background: #304156;
    top: 0;
    left: 0;
    height: 100%;
    width: 230px;
    z-index: 100;
    transition: all 0.1s ease;
}

.my-sidebar.my-close {
    width: 78px;
}

.my-sidebar .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}

.my-sidebar .logo-details i {
    font-size: 30px;
    color: #fff;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
}

.my-sidebar .logo-details .logo_name {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.1s ease;
    transition-delay: 0.1s;
}

.my-sidebar.my-close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.my-sidebar .my-nav-links {
    height: 100%;
    padding: 30px 0 150px 0;
    overflow: auto;
}

.my-sidebar.my-close .my-nav-links {
    overflow: visible;
}

.my-sidebar .my-nav-links::-webkit-scrollbar {
    display: none;
}

.my-sidebar .my-nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.1s ease;
}

.my-sidebar .my-nav-links>li.active:before,
.my-sidebar .my-nav-links>li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 4px;
    height: 100%;
    background: #1F2D3D;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
}

.my-sidebar .my-nav-links li.active:before,
.my-sidebar .my-nav-links li:hover:before {
    opacity: 1;

}

.my-sidebar .my-nav-links li .my-icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my-sidebar.my-close .my-nav-links li .my-icon-link {
    display: block
}

.my-sidebar .my-nav-links li i {
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.1s ease;
}

.my-sidebar .my-nav-links li.active i,
.my-sidebar .my-nav-links li:hover i {
    color: #fff;
}

.my-sidebar .my-nav-links li.showMenu i.my-arrow {
    transform: rotate(-180deg);
}

.my-sidebar.my-close .my-nav-links i.my-arrow {
    display: none;
}

.my-sidebar .my-nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.my-sidebar .my-nav-links li a .my-link_name {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    transition: all 0.1s ease;
}

.my-sidebar .my-nav-links li.active a .my-link_name,
.my-sidebar .my-nav-links li:hover a .my-link_name {
    color: #fff;

}

.my-sidebar.my-close .my-nav-links li a .my-link_name {
    opacity: 0;
    pointer-events: none;
}

.my-sidebar .my-nav-links li .my-sub-menu {
    padding: 6px 6px 14px 80px;
    background: #1F2D3D;
    display: none;
}

.my-sidebar .my-nav-links li.showMenu .my-sub-menu {
    display: block;
}

.my-sidebar .my-nav-links li .my-sub-menu a {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.8;
    transition: all 0.1s ease;
}

.my-sidebar .my-nav-links li .my-sub-menu a:hover {
    opacity: 1;
}

.my-sidebar.my-close .my-nav-links li .my-sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
}

.my-sidebar.my-close .my-nav-links li:hover .my-sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.1s ease;
}

.my-sidebar .my-nav-links li .my-sub-menu .my-link_name {
    display: none;
}

.my-sidebar.my-close .my-nav-links li .my-sub-menu .my-link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.my-sidebar .my-nav-links li .my-sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.my-sidebar .my-nav-links li:hover .my-sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
}

.my-sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.1s ease;
}

.my-sidebar.my-close .profile-details {
    background: none;
}

.my-sidebar.my-close .profile-details {
    width: 78px;
}

.my-sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.my-sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.1s ease;
}

.my-sidebar.my-close .profile-details img {
    padding: 10px;
}

.my-sidebar .profile-details .profile_name,
.my-sidebar .profile-details .job {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
}

.my-sidebar .profile-details .job {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    opacity: .5;
    white-space: nowrap;
}

.my-sidebar .profile-details i.bx {
    min-width: 50px;
}

.my-sidebar.my-close .profile-details i,
.my-sidebar.my-close .profile-details .profile_name,
.my-sidebar.my-close .profile-details .job {
    display: none;
}

.my-sidebar .profile-details .job {
    font-size: 12px;
}

.my-home-section {
    position: relative;
    background: #fff;
    min-height: 100%;
    left: 230px;
    width: calc(100% - 230px);
    transition: all 0.1s ease;
    padding: 0 10px;
}

.my-sidebar.my-close~.my-home-section {
    left: 78px;
    width: calc(100% - 78px);
}

.my-home-section .my-home-content {
    height: 60px;
    display: flex;
    align-items: center;
}

.my-home-section .my-home-content .bx-menu,
.my-home-section .my-home-content .text {
    color: #11101d;
    font-size: 35px;
}

.my-home-section .my-home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
}

.my-home-section .my-home-content .text {
    font-size: 26px;
    font-weight: 600;
}

.my-item:hover {
    background-color: #263445;
    ;
}



.my-sidebar-handler i {
    cursor: pointer;
}



@media (max-width: 420px) {
    .my-sidebar.my-close .my-nav-links li .my-sub-menu {
        display: none;
    }
}

input[type="file"]::file-selector-button {
    padding: 5px;
    border-radius: 2px;
    background-color: #0275d8;
    border: 1px solid #0275d8;
    color: #fff;
    cursor: pointer;
}

input[type="file"]::file-selector-button:hover {
    background-color: #0065bd;
    border: 1px solid #0275d8;
    color: #fff;
}