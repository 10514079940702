/* * {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    outline: none;
    font-weight: normal;
}

body {
    background: #3498DB;
    font-family: "Segoe UI", "Microsoft YaHei";
} */


a {
    color: #fff;
    text-decoration: none;
}

header {
    color: #fff;
    text-align: center;
    min-height: 140px;
    margin-bottom: 60px;
}

header h1 {
    margin-top: 100px;
    font-size: 50px;
    margin-bottom: 20px;
    font-weight: 100;
}

header a {
    font-size: 18px;
    margin-left: 20px;
}

.copyright {
    font-size: 25px;
    font-weight: 100;
    color: #fff;
    text-align: center;
    margin-top: 100px;
}

/* Tabs Start */

.ease {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.tabs {
    background: #fff;
    position: relative;
    margin-bottom: 50px;
}

.tabs>input,
.tabs>span {
    width: 10%;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
}

.tabs>input {
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    z-index: 99;
}

.tabs>span {
    background: #ffffff;
    text-align: center;
    overflow: hidden;
    font-size: 15px;
}

.tabs>span i,
.tabs>span {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.tabs>input:hover+span {
    background: rgba(255, 255, 255, .1);
}

.tabs>input:checked+span {
    background: #fff;
}

.tabs>input:checked+span,
.tabs>input:hover+span {
    color: #3498DB;
}

#tab-1,
#tab-1+span {
    left: 0;
}

#tab-2,
#tab-2+span {
    left: 10%;
}

#tab-3,
#tab-3+span {
    left: 20%;
}

#tab-4,
#tab-4+span {
    left: 30%;
}

#tab-5,
#tab-5+span {
    left: 40%;
}

#tab-6,
#tab-6+span {
    left: 50%;
}


.tab-content {
    padding-top: 40px;
    width: 100%;
    min-height: 340px;
}

.tab-content section {
    width: 100%;
    display: none;
}

.tab-content section h1 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
}

#tab-1:checked~.tab-content #tab-item-1 {
    display: block;
}

#tab-2:checked~.tab-content #tab-item-2 {
    display: block;
}

#tab-3:checked~.tab-content #tab-item-3 {
    display: block;
}

#tab-4:checked~.tab-content #tab-item-4 {
    display: block;
}

#tab-5:checked~.tab-content #tab-item-5 {
    display: block;
}

#tab-6:checked~.tab-content #tab-item-6 {
    display: block;
}

/* effect-3 */

.effect-3 .line {
    background: #3498DB;
    width: 10%;
    height: 2px;
    position: absolute;
    top: 36px;
}

#tab-1:checked~.line {
    left: 0;
}

#tab-2:checked~.line {
    left: 10%;
}

#tab-3:checked~.line {
    left: 20%;
}

#tab-4:checked~.line {
    left: 30%;
}

#tab-5:checked~.line {
    left: 40%;
}

#tab-6:checked~.line {
    left: 50%;
}

.colors {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.color-span {
    width: 25px;
    height: 25px;
}

input[type='color'] {
    width: 40px;
    height: 40px;
    padding: 0;
}

.param-image {
    width: 40px !important;
    height: 40px !important;
}

table:hover {
    background-color: #fff;
}