.main-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    gap: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.header i {
    border: 1px solid #304156;
    position: relative;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.header i:hover {
    background-color: rgb(235, 243, 251);
}

.count-badge {
    background-color: red;
    position: absolute;
    top: -30%;
    right: -25%;
    color: white;
    border-radius: 7px;
    padding: 3px;
    font-size: 14px;
    font-weight: normal;
}